import React from "react";
import { Paper, makeStyles } from "@material-ui/core";

// stylings for the page
const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: "rgb(10, 25, 41)",
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    colorScheme: "dark",
    flexDirection: "column",
  },
  card: {
    backgroundColor: "rgb(0, 30, 60)",
    colorScheme: "dark",
    border: "1px solid rgb(19, 47, 76)",
    color: "white",
    padding: "3rem",
  },
  input: {
    width: "350px",
  },
}));

const ThankYouPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Paper className={classes.card} elevation={4}>
        <h4>Até logo!</h4>
      </Paper>
    </div>
  );
};

export default ThankYouPage;
